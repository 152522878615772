import React, { useState } from "react";
import "./About.css";
import MemberCard from "./MemberCard";

function About() {
  const [leaders, setleaders] = useState([
    { name: "R. S. Ananda Bhattar", role: "Head Priest" },
    { name: "Jagdish Agrawal", role: "Treasurer" },
    { name: "​Manjesh Agrawal ", role: "President" },
    { name: "Madhulika Srivastava", role: "Internet Director" },
    { name: "Ashok Rajput", role: "Deputy Treasurer" },
    { name: "Anwar Zaidi", role: "Legal Advisor" },
    { name: "​Kapil Pothakanoori", role: "Webmaster" },
    { name: "​Poonam Verma", role: "Facebook Admin" },
  ]);

  const [volunteers, setVolunteers] = useState([
    { name: "Milind Mishra", role: "Volunteer" },
    { name: "Sahil Jaggi", role: "Volunteer" },
    { name: "Sriram and Pavithra Rangaswamy", role: "Volunteers" },
    { name: "​Kavita Mishra", role: "Volunteer" },
    { name: "Bharati ", role: "Volunteer" },
    { name: "​Neeti and Ravi Sharma", role: "Volunteers" },
    { name: "Rajita Chenna", role: "Special Events" },
    { name: "Kamlesh Saini", role: "Volunteer" },
    { name: "Sheela Rajput", role: "Volunteer" },
    { name: "Jitin Agarwal", role: "Volunteer" },
    { name: "Rajlaxmi", role: "Volunteer" },
    { name: "​Sandeep Verma", role: "Volunteer" },
    { name: "Sathya Ramamurthy", role: "Volunteer" },
    { name: "Srinivasan Ramamurthy", role: "Volunteer" },
  ]);

  const [previousVonlunteers, setPreviousVolunteers] = useState([
    "Praveen Kodumuru",
    "Eshwar Udho",
    "​Mounika Kodumuru",
    "Hitesh Mangal ",
    "Meenakshi Ganesan",
    "Alka Chanana ",
    "Vishal Chanana",
    "Radha Damaraju",
    "Jitin Agarwal",
    "Shubhakant Pandey",
    "Suchismita Pandey",
  ]);
  return (
    <div className="about">
      <div className="about__intro">
        <h1>History</h1>
        <p>
          Mandir of Madison was founded in 2007 by Jagdish and Manjesh Agrawal
          in Sun Prairie, Wisconsin. With the help of countless volunteers of
          the years they have maintained the temple for the greater Madison
          community of Hindus. Below is a rough list of dedicated contributors
          who have been part of our growth.
        </p>
      </div>
      <div className="teamsinfo">
        <div className="about_leadership">
          <h1>Leadership Team</h1>
          <div className="about_leadership_container">
            <div className="about_leadership_list">
              {leaders.map((leader) => (
                <MemberCard name={leader.name} role={leader.role} r />
              ))}
            </div>
          </div>
        </div>

        <div className="about_volunteer">
          <h1>Current Volunteers</h1>
          <div className="about_volunteer_container">
            <div className="about_volunteer_list">
              {volunteers.map((leader) => (
                <MemberCard name={leader.name} role={leader.role} r />
              ))}
            </div>
          </div>
        </div>
        <div className="about_previous_volunteer">
          <h1> Previous Volunteers</h1>
          <div className="about_previous_volunteer_container">
            <div className="about_previous_volunteer_list">
              {previousVonlunteers.map((leader) => (
                <MemberCard name={leader} r />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
