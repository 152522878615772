import React from "react";
import { useForm } from "react-hook-form";
import "./ContactForm.css";

function ContactForm() {
  const { register, handleSubmit, watch, errors } = useForm();
  const onSubmit = (data) => console.log(data);
  console.log(watch("example"));
  return (
    <div className="contactform">
      <h1>Contact Form</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formitem">
          <label>Email :</label>
          <input name="email" defaultValue="user@example.com" ref={register} />
        </div>
        <div className="formitem">
          <label>Name :</label>
          <input name="name" ref={register({ required: true })} />
        </div>
        {errors.name && <span>This field is required</span>}
        <div className="formitem">
          <input type="submit" />
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
