import React from "react";
import "./Header.css";
import logo from "../../images/mandirlogo.png";
import { Link } from "react-router-dom";
function Header() {
  return (
    <header className="header">
      {/* LOGO */}
      <Link className="text-link" to="/home">
        <img className="header__logo" src={logo} alt="" />
      </Link>
      {/* Navigation Headers 7 */}
      <Link className="text-link" to="/home">
        <div className="header__navitem">Home</div>
      </Link>
      <Link className="text-link" to="/donate">
        <div className="header__navitem">Donate</div>
      </Link>
      <Link className="text-link" to="/services">
        <p className="header__navitem">Services</p>
      </Link>
      <Link className="text-link" to="/about">
        <p className="header__navitem">About</p>
      </Link>
      <Link className="text-link" to="/contact">
        <p className="header__navitem">Contact</p>
      </Link>
      <Link className="text-link" to="/gallery">
        <p className="header__navitem">Gallery</p>
      </Link>
    </header>
  );
}

export default Header;
