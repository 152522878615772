import React from "react";

function MandirAddress() {
  return (
    <div className="mandiraddress">
      <h2>Mandir Address</h2>
      <br />
      <h5>Mandir of Madison & Community Center</h5>
      <p>3393 Burke Rd Sun Prairie,</p>
      <p>WI 53590</p>
      <p>
        ​<a href="tel:608-825-9200">(608) 825-9200</a>
      </p>
    </div>
  );
}

export default MandirAddress;
