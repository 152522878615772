import { MandirTimings } from "./MandirTimings";
import React from "react";
import "./Contact.css";
import ContactForm from "./ContactForm";
import MandirAddress from "../mandiraddress/MandirAddress";
function Contact() {
  return (
    <div className="contact">
      <div className="contact__left">
        <MandirAddress />
        <MandirTimings />
      </div>
      <div className="contact__right">
        <ContactForm />
      </div>
    </div>
  );
}

export default Contact;
