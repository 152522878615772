import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import Paypal from "../donate/Paypal";
import MandirAddress from "../mandiraddress/MandirAddress";
function Footer() {
  return (
    <div className="footer">
      {/* Address */}
      <div className="footer__address">
        <MandirAddress />
      </div>
      <div className="footer__paypal">
        <Paypal />
      </div>
      <div className="footer__links">
        <div className="footer__links__left">
          <Link className="text-link" to="/home">
            <p className="footer__navitem ">Home</p>
          </Link>
          <Link className="text-link" to="/donate">
            <p className="footer__navitem">Donate</p>
          </Link>
          <Link className="text-link" to="/services">
            <p className="footer__navitem">Services</p>
          </Link>
        </div>
        <div className="footer__links__right">
          <Link className="text-link" to="/about">
            <p className="footer__navitem">About</p>
          </Link>
          <Link className="text-link" to="/contact">
            <p className="footer__navitem">Contact</p>
          </Link>
          <Link className="text-link" to="/gallery">
            <p className="header__navitem">Gallery</p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Footer;
