import ImageGallery from "react-image-gallery";
import kalabhairav from "../../images/kalabhairav.jpg";
import saibaba from "../../images/saibaba.jpg";
import srinivasa from "../../images/srinivasa.jpg";
import durga from "../../images/durga.jpg";
import shiva from "../../images/shiva.jpg";
import "./Gallery.css";
import { useEffect, useState } from "react";

function GalleryPics() {
  const [images, setImages] = useState([]);
  const arr = [durga, kalabhairav, saibaba, srinivasa, durga, shiva];
  const createImageGalleryObj = (arr) => {
    var imagesList = [];
    arr.forEach(x => {
      var image = new Object();
      image.original = String(x);
      image.thumbnail = String(x);
      imagesList.push(image);
    })
    return imagesList;
  };

  useEffect(() => {
    setImages(createImageGalleryObj(arr))
  }, []);

  return <ImageGallery items={images} />;
}
export default GalleryPics;
