import React from "react";
import "./Donate.css";
import Paypal from "./Paypal";
function Donate() {
  return (
    <div className="donate">
      <p>If you wish to make a donation, please use the button below.</p>
      <p>
        <Paypal />
      </p>
      <p>
        Donate with PayPal button Your Donation is completely tax deductible.
        Please save your receipt for your records. Mandir of Madison thanks you
        for your generous donation. Please visit the mandir to receive blessings
        and prasadam.
      </p>
    </div>
  );
}

export default Donate;
