import "./App.css";
import Header from "../components/header/Header";
import MainContent from "../components/maincontent/MainContent";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Donate from "../components/donate/Donate";
import Services from "../components/services/Services";
import Contact from "../components/contact/Contact";
import About from "../components/about/About";
import GalleryPics from "../components/gallery/Gallery";
function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <Switch>
          <Route path="/home">
            <MainContent />
            <Footer />
          </Route>
          <Route path="/donate">
            <Donate />
            <Footer />
          </Route>
          <Route path="/services">
            <Services />
            <Footer />
          </Route>
          <Route path="/contact">
            <Contact />
            <Footer />
          </Route>
          <Route path="/about">
            <About />
            <Footer />
          </Route>
          <Route path="/gallery">
            <GalleryPics />
            <Footer />
          </Route>
          <Route path="/">
            <MainContent />
            <Footer />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
