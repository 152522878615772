import React from "react";
import "./MainContent.css";
import kalabhairav from "../../images/kalabhairav.jpg";
import saibaba from "../../images/saibaba.jpg";
import srinivasa from "../../images/srinivasa.jpg";
import durga from "../../images/durga.jpg";
import shiva from "../../images/shiva.jpg";

function MainContent() {
  return (
    <div className="maincontent">
      <div className="maincontent__left">
        {/* top1 */}
        <div className="maincontent__left__top1">
          <img className="shiva img__rounded" src={shiva} alt="" />
          <img className="durga img__rounded" src={durga} alt="" />
          <img className="saibaba img__rounded" src={saibaba} alt="" />
        </div>

        {/* down1 */}
        <div className="maincontent__left__down1">
          <img className="srinivasa img__rounded" src={srinivasa} alt="" />
          <img className="kalabhairav img__rounded" src={kalabhairav} alt="" />
        </div>
      </div>
      <div className="maincontent__right">
        {/* Title */}
        <h1 className="welcome__title">Welcome to Mandir of Madison</h1>
        <h5 className="welcome__note">
          Mandir of Madison, one of Wisconsin's leading temples is providing
          exceptional quality service in the form of spiritual guidance and
          cultural preservation
        </h5>
        {/* About */}
      </div>
    </div>
  );
}

export default MainContent;
