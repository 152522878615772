import React from "react";
export function MandirTimings({}) {
  return (
    <div className="mandirtimings">
      <h1>Mandir Hours</h1>
      <br />
      <h3>TIMINGS DURING CORONA PANDEMIC</h3>
      <br />
      <h4>EVERY-DAY : 6:30PM to 8:30PM including weekends</h4>
      <p>Monday - Friday 6:30 pm-8:30 pm</p>
      <p>Saturday 6:30 pm-8:30 pm</p>
      <p>Sunday/Holidays 6:30 pm-8:30 pm</p>
      <br />
      <sub>
        <b>
          Temple has changed timings ( until further notice) to align with CDC
          COVID-19 related requirements. All devotees are requested to follow
          the Mask & Social distancing guideline during their visits
        </b>
      </sub>
    </div>
  );
}
