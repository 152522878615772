import React from "react";
import "./Services.css";
function Services() {
  return (
    <div className="services">
      <h2>Puja Services</h2>
      <p>
        The file below indicates the puja ceremonies offered and their
        respective service fees.
      </p>

      <iframe
        height={1500}
        width="100%"
        src="https://www.scribd.com/embeds/421677611/content?access_key=key-W21LfLklJpEub2ILaVdf&amp;jsapi=true&amp;xdm_e=https://www.mandirofmadison.org&amp;xdm_c=default0&amp;xdm_p=1"
        id=""
      ></iframe>
    </div>
  );
}

export default Services;
