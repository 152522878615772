import React from "react";
import "./MemberCard.css";
function MemberCard({ name, role }) {
  return (
    <div className="membercard">
      <h5>{name}</h5>
      <p>​{role}</p>
    </div>
  );
}

export default MemberCard;
